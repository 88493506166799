<template>
  <div>
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- Reset question selection confirmation dialog -->
    <v-dialog v-model="resetQuestionSelectionDialog" max-width="390">
      <v-card>
        <v-card-title class="headline">
          Reset selected questions ?
        </v-card-title>

        <v-card-text>
          Are you sure you want to reset the selected questions for
          <b class="text-capitalize">'{{ resetSectionSelected }}'</b> section ?
          <br /><br />
          This action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            dark
            @click="resetQuestionSelection(resetSectionSelected)"
          >
            Reset
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="resetQuestionSelectionDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <!-- Error Row -->
      <v-row justify="center" v-if="error">
        <v-alert type="error" class="mt-4 mx-auto">
          {{ error }}
        </v-alert>
      </v-row>

      <!-- Stepper Card Row -->
      <v-row justify="center">
        <v-card :loading="loading || superLoading" class="w-100">
          <v-stepper v-model="stepper" vertical>
            <!-- 1. Test Name and Instructions -->
            <v-stepper-step :complete="stepper > 1" step="1" editable>
              Test Name and Instructions
              <small class="mt-1">Short and simple</small>
            </v-stepper-step>
            <v-stepper-content class="ma-0 pa-2 ml-md-12 pa-md-4" step="1">
              <v-card color="blue lighten-4" class="mb-12 pa-2 pa-md-6">
                <v-form
                  @submit.prevent="stepper = 2"
                  v-model="testNameFormValid"
                  ref="testNameForm"
                >
                  <v-text-field
                    autofocus
                    v-model="testName"
                    type="text"
                    :rules="basicRules"
                    label="Test Name"
                    :disabled="loading || superLoading"
                    required
                  ></v-text-field>
                  <v-textarea
                    v-model="additionalInstructions"
                    type="text"
                    label="Additional Test Instructions (Optional)"
                    :disabled="loading || superLoading"
                    required
                  ></v-textarea>
                </v-form>
              </v-card>
              <v-btn
                color="primary"
                :disabled="loading || !testNameFormValid || superLoading"
                @click="stepper = 2"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <!-- 2. Select Courses and Test Groups -->
            <v-stepper-step
              :complete="stepper > 2"
              step="2"
              :editable="stepper > 2"
            >
              Courses and Test Group
              <small class="mt-1"
                >Select Courses and Test Groups to deploy the test for.</small
              >
            </v-stepper-step>
            <v-stepper-content class="ma-0 pa-2 ml-md-12 pa-md-4" step="2">
              <v-card color="blue lighten-4" class="mb-12 pa-2 pa-md-6">
                <v-form
                  @submit.prevent="stepper = 3"
                  v-model="coursesFormValid"
                  ref="coursesForm"
                >
                  <v-select
                    v-model="selectedCourses"
                    :items="Object.keys(courses)"
                    item-text="courseName"
                    :rules="selectRules"
                    @change="syncTestGroupsAndCourses()"
                    chips
                    label="Select Courses"
                    multiple
                    solo
                  ></v-select>

                  <template v-if="selectedCourses.length > 0">
                    <div
                      v-for="(course, i) in selectedCourses"
                      :key="i + '_' + course + '_selectTestGroup'"
                    >
                      <span>{{ course }} :</span>
                      <v-select
                        v-model="selectedTestGroup[course]"
                        :items="courses[course].testGroups"
                        class="mt-2"
                        :rules="selectRules"
                        chips
                        label="Select Test Group"
                        required
                        solo
                      ></v-select>
                    </div>
                  </template>
                </v-form>
              </v-card>
              <v-btn
                color="primary"
                :disabled="
                  loading ||
                  superLoading ||
                  !coursesFormValid ||
                  Object.keys(selectedTestGroup).length !=
                    selectedCourses.length
                "
                @click="stepper = 3"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <!-- 3. Test Marking -->
            <v-stepper-step
              :complete="stepper > 3"
              step="3"
              :editable="stepper > 3"
            >
              Marking Settings
              <small class="mt-1">Negative marking etc.</small>
            </v-stepper-step>
            <v-stepper-content class="ma-0 pa-2 ml-md-12 pa-md-4" step="3">
              <v-card color="blue lighten-4" class="mb-12 pt-4 pa-2 pa-md-6">
                <v-form
                  @submit.prevent="stepper = 4"
                  v-model="markingFormValid"
                  ref="markingForm"
                >
                  <div class="d-md-flex">
                    <v-text-field
                      prefix="+ "
                      color="deep-purple accent-4"
                      v-model="rewardPoints"
                      maxlength="10"
                      type="number"
                      min="0"
                      :rules="basicRules"
                      label="Reward Points / Question"
                      class="mx-1"
                      :disabled="loading || superLoading"
                      outlined
                      required
                    ></v-text-field>
                    <v-text-field
                      prefix="- "
                      color="deep-purple accent-4"
                      v-model="punishmentPoints"
                      maxlength="10"
                      type="number"
                      min="0"
                      :rules="basicRules"
                      label="Punishment Points / Question"
                      class="mx-1"
                      :disabled="loading || superLoading"
                      outlined
                      required
                    ></v-text-field>
                  </div>
                </v-form>
              </v-card>
              <v-btn
                color="primary"
                :disabled="loading || !markingFormValid || superLoading"
                @click="stepper = 4"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <!-- 4. Create Sections -->
            <v-stepper-step
              :complete="stepper > 4"
              step="4"
              :editable="stepper > 4"
            >
              Sections
              <small class="mt-1">Create sections to group questions.</small>
            </v-stepper-step>
            <v-stepper-content class="ma-0 pa-2 ml-md-12 pa-md-4" step="4">
              <v-card color="blue lighten-4" class="mb-12 pt-4 pa-2 pa-md-6">
                <v-form
                  @submit.prevent="addSection"
                  v-model="sectionFormValid"
                  ref="sectionForm"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="sectionName"
                      type="text"
                      :rules="basicRules"
                      label="Section Name"
                      class="mx-1"
                      @enter.prevent="addSection"
                      :disabled="loading || superLoading"
                      solo
                      required
                    ></v-text-field>
                    <v-btn @click="addSection" color="primary" class="mt-2">
                      <v-icon>mdi-plus</v-icon> Add</v-btn
                    >
                  </div>
                  <div class="mt-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Serial No.</th>
                            <th class="text-left">Section Name</th>
                            <th class="text-left">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(section, i) in sections" :key="i">
                            <td>{{ i + 1 }}</td>
                            <td class="text-capitalize">{{ section }}</td>
                            <td>
                              <v-btn icon @click="deleteSection(i, section)">
                                <v-icon color="red">mdi-trash-can</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-form>
              </v-card>
              <v-btn
                color="primary"
                :disabled="loading || sections.length < 1 || superLoading"
                @click="stepper = 5"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <!-- 5. Select Questions -->
            <v-stepper-step
              :complete="stepper > 5"
              step="5"
              :editable="stepper > 5"
            >
              Select Questions
              <small class="mt-1">Add Questions to your sections</small>
            </v-stepper-step>
            <v-stepper-content class="ma-0 pa-2 ml-md-12 pa-md-4" step="5">
              <v-card color="blue lighten-4" class="mb-12 pt-4 pa-1 pa-md-6">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Serial No.</th>
                        <th class="text-left">Section</th>
                        <th class="text-left">Questions</th>
                        <th class="text-left">Select Folder</th>
                        <th class="text-left">or Select Manually</th>
                        <!-- <th class="text-left">Reset Selection</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(section, i) in sections" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td class="text-capitalize">{{ section }}</td>
                        <td class="text-capitalize">
                          {{ displayNoOfQuestions(section) }}
                        </td>
                        <td>
                          <!-- TODO: Add Select Multiple folders option -->
                          <v-select
                            label="Select folder"
                            class="text-capitalize mt-7"
                            color="blue darken-1"
                            v-model="selectedFolder[section]"
                            :items="questionFolders"
                            :disabled="loading || superLoading"
                            @change="folderSelected($event, section)"
                            outlined
                            dense
                          ></v-select>
                        </td>
                        <td>
                          <!-- TODO: implement show selected questions for folders -->
                          <SelectQuestionsDialog
                            :superLoading="superLoading"
                            @setSuperLoading="setLoading"
                            :sectionName="section"
                            :selectedQuestions="selectedQuestions[section]"
                            @questionsSelected="questionsSelected"
                          />
                        </td>
                        <!-- <td>
                          <v-btn
                            @click="openResetQuestionsDialog(section)"
                            icon
                            color="red"
                          >
                            <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                        </td> -->
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <v-btn
                color="primary"
                :disabled="loading || !selectQuestionsValid() || superLoading"
                @click="stepper = 6"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <!-- 6. Test Timings -->
            <v-stepper-step
              :complete="stepper > 6"
              step="6"
              :editable="stepper > 6"
            >
              Test Timings
              <small class="mt-1">Test duration etc.</small>
            </v-stepper-step>
            <v-stepper-content class="ma-0 pa-2 ml-md-12 pa-md-4" step="6">
              <v-card color="blue lighten-4" class="mb-12 pt-4 pa-2 pa-md-6">
                <v-form
                  @submit.prevent
                  v-model="timingsFormValid"
                  ref="timingsForm"
                >
                  <!-- Test Availability -->
                  <div>
                    <span>Test Availability:</span>
                    <div class="d-md-flex mt-4">
                      <!-- Start Date Field -->
                      <v-menu
                        ref="start_date_menu"
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="startDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            class="mx-4"
                            v-model="startDateText"
                            label="Start Date"
                            prepend-icon="mdi-calendar-clock"
                            :rules="basicRules"
                            v-on="on"
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :min="currentDate"
                          v-model="startDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="startDateMenu = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.start_date_menu.save(startDate)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>

                      <!-- Start Time Field -->
                      <v-menu
                        ref="startTimeMenu"
                        v-model="startTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="startTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            class="mx-4"
                            v-model="startTimeText"
                            label="Start Time"
                            prepend-icon="mdi-timeline-clock"
                            :rules="basicRules"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="startTimeMenu"
                          v-model="startTime"
                          full-width
                          @click:minute="$refs.startTimeMenu.save(startTime)"
                        ></v-time-picker>
                      </v-menu>
                    </div>

                    <div class="d-md-flex mt-4">
                      <!-- End Date Field -->
                      <v-menu
                        ref="end_date_menu"
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="endDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            class="mx-4"
                            v-model="endDateText"
                            label="End Date"
                            prepend-icon="mdi-calendar-clock"
                            :rules="basicRules"
                            v-on="on"
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :min="startDate"
                          v-model="endDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="endDateMenu = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.end_date_menu.save(endDate)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>

                      <!-- End Time Field -->
                      <v-menu
                        ref="endTimeMenu"
                        v-model="endTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="endTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            class="mx-4"
                            v-model="endTimeText"
                            label="End Time"
                            prepend-icon="mdi-timeline-clock"
                            :rules="basicRules"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="endTimeMenu"
                          v-model="endTime"
                          :min="startDate == endDate ? startTime : null"
                          full-width
                          @click:minute="$refs.endTimeMenu.save(endTime)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                  </div>

                  <v-divider class="my-6"></v-divider>

                  <!-- Test Duration  -->
                  <div>
                    <span>Test Duration:</span>
                    <br />
                    <small
                      >Maximum Duration is {{ maxTestDuration }} minutes
                      according to test availability.</small
                    >
                    <v-container fluid>
                      <v-row justify="center">
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            suffix="minutes"
                            v-model="testDuration"
                            type="number"
                            :min="1"
                            :max="maxTestDuration"
                            :rules="durationRules()"
                            label="Test Duration"
                            :disabled="loading || superLoading"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>

                  <v-divider class="my-6"></v-divider>

                  <!-- Allow submit before time -->
                  <div>
                    <v-checkbox
                      v-model="submitBeforeTime"
                      label="Allow students to submit before duration is complete"
                    ></v-checkbox>
                  </div>
                </v-form>
              </v-card>
              <v-btn
                color="primary"
                :disabled="loading || !timingsFormValid || superLoading"
                @click="stepper = 7"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <!-- 7. Test Settings -->
            <v-stepper-step
              :complete="stepper > 7"
              step="7"
              :editable="stepper > 7"
            >
              Test Settings
              <small class="mt-1">Deploy as premium etc.</small>
            </v-stepper-step>
            <v-stepper-content class="ma-0 pa-2 ml-md-12 pa-md-4" step="7">
              <v-card color="blue lighten-4" class="mb-12 pt-4 pa-2 pa-md-6">
                <v-form
                  @submit.prevent="deployTest"
                  v-model="settingsFormValid"
                  ref="settingsForm"
                >
                  <!-- Deploy as Premium -->
                  <div>
                    <v-checkbox
                      v-model="isPremium"
                      label="Deploy as Premium"
                    ></v-checkbox>
                  </div>

                  <!-- Deploy as Quiz -->
                  <div>
                    <v-checkbox
                      v-model="isQuiz"
                      label="Deploy as Quiz"
                    ></v-checkbox>
                  </div>

                  <!-- Highlight test -->
                  <div>
                    <v-checkbox
                      v-model="isHighlighted"
                      label="Highlight Test"
                    ></v-checkbox>
                  </div>

                  <!-- Allow section switching -->
                  <div>
                    <v-checkbox
                      v-model="allowSectionSwitching"
                      label="Allow Section Switching"
                    ></v-checkbox>
                  </div>

                  <!-- Deploy as bi-lingual -->
                  <div>
                    <v-checkbox
                      v-model="isBilingual"
                      @change="onlySecondaryLang = false"
                      label=" Deploy as bi-lingual (else uses only the primary language)"
                    ></v-checkbox>
                  </div>

                  <!-- Use secondary language -->
                  <div v-if="!isBilingual">
                    <v-checkbox
                      v-model="onlySecondaryLang"
                      label="Use ONLY secondary language"
                    ></v-checkbox>
                  </div>
                </v-form>
              </v-card>
              <v-btn
                color="primary"
                :disabled="loading || !timingsFormValid || superLoading"
                @click="deployTest"
              >
                {{ !testObj ? "Deploy" : "Update" }}&nbsp;Test
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const dayjs = require("dayjs");
const isEqual = require("lodash.isequal");

import SelectQuestionsDialog from "@/components/Home/Create/Test/SelectQuestionsDialog.vue";

export default {
  name: "TestForm",
  props: ["superLoading", "testObj"],
  components: {
    SelectQuestionsDialog,
  },

  computed: {
    startDateText: {
      get() {
        return this.startDate
          ? dayjs(this.startDate).format("dddd, MMMM D YYYY")
          : "";
      },
      set(newDateText) {
        return newDateText;
      },
    },

    endDateText: {
      get() {
        return this.endDate
          ? dayjs(this.endDate).format("dddd, MMMM D YYYY")
          : "";
      },
      set(newDateText) {
        return newDateText;
      },
    },

    startTimeText: {
      get() {
        return this.startTime
          ? dayjs("1/1/1 " + this.startTime).format("hh:mm a")
          : "";
      },
      set(newDateText) {
        return newDateText;
      },
    },

    endTimeText: {
      get() {
        return this.endTime
          ? dayjs("1/1/1 " + this.endTime).format("hh:mm a")
          : "";
      },
      set(newDateText) {
        return newDateText;
      },
    },

    currentDate() {
      return new Date().toISOString();
    },

    maxTestDuration() {
      const startDateTime = dayjs(this.startDate + " " + this.startTime);
      const endDateTime = dayjs(this.endDate + " " + this.endTime);
      const diff = endDateTime.diff(startDateTime, "minutes", true);
      return !isNaN(diff) ? diff : 1;
    },
  },

  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    stepper: 1,
    courses: [],
    questionFolders: [],
    selectedFolder: {},
    resetQuestionSelectionDialog: false,
    resetSectionSelected: "",
    startDateMenu: false,
    startTimeMenu: false,
    endDateMenu: false,
    endTimeMenu: false,

    // forms
    testNameFormValid: false,
    coursesFormValid: false,
    markingFormValid: false,
    sectionFormValid: false,
    timingsFormValid: false,
    settingsFormValid: false,

    //form rules
    basicRules: [(value) => !!value || "Required."],
    selectRules: [
      (value) =>
        (value && value.length > 0) || "Please select at least one option.",
    ],

    // form values
    testName: "",
    additionalInstructions: "",
    selectedCourses: [],
    selectedTestGroup: {},
    rewardPoints: "0",
    punishmentPoints: "0",
    sectionName: "",
    sections: [],
    selectedQuestions: {},
    questionCountObj: {},
    startDate: dayjs().format().substr(0, 10),
    startTime: null,
    endDate: dayjs().format().substr(0, 10),
    endTime: null,
    testDuration: 1,
    submitBeforeTime: true,
    isPremium: false,
    isQuiz: false,
    isHighlighted: false,
    allowSectionSwitching: false,
    isBilingual: false,
    onlySecondaryLang: false,
    oldTestGroups: {},
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    //called when selected course changes
    syncTestGroupsAndCourses() {
      //compare selected course and test groups
      //loop through testGroup:
      Object.keys(this.selectedTestGroup).forEach((course) => {
        // if course array !includes course from testGroup
        if (!this.selectedCourses.includes(course)) {
          //  remove in testGroup obj
          this.$delete(this.selectedTestGroup, course);
        }
      });
    },

    addSection() {
      this.snackbar = false;
      if (this.sections.includes(this.sectionName.toLowerCase())) {
        this.snackbarText = "This section name already exists :(";
        this.snackbar = true;

        return;
      } else if (this.sectionName) {
        this.sections.push(this.sectionName);
        this.sectionName = "";
      }
      return;
    },

    deleteSection(index, sectionName) {
      this.sections.splice(index, 1);
      // delete all selected questions for the section
      this.resetQuestionSelection(sectionName);
      //reset the folder selected
      this.resetFolderSelection(sectionName);
    },

    questionsSelected(payload) {
      this.$set(
        this.selectedQuestions,
        payload.sectionName,
        payload.selectedQuestions
      );
      this.selectedFolder[payload.sectionName] = "";
    },

    displayNoOfQuestions(section) {
      if (section in this.selectedQuestions) {
        if (this.selectedQuestions[section] instanceof Array) {
          // update questionCountObj for questionCount in payload
          this.$set(
            this.questionCountObj,
            section,
            this.selectedQuestions[section].length
          );

          return `${this.selectedQuestions[section].length}`;
        }

        //if not array : fetch number from firestore;

        // get question Count from store
        var questionCount = this.$store.getters.questionCount;

        // update questionCountObj for questionCount in payload
        this.$set(
          this.questionCountObj,
          section,
          questionCount[this.selectedQuestions[section].folderName]
        );

        return questionCount[this.selectedQuestions[section].folderName];
      } else {
        return 0;
      }
    },

    folderSelected(value, sectionName) {
      this.$set(this.selectedQuestions, sectionName, { folderName: value });
      this.$set(this.selectedFolder, sectionName, value);
    },

    selectQuestionsValid() {
      //run only when select Questions form is displayed
      if (this.stepper == 5) {
        //  displayNoOfQuestions for each section is zero then not valid
        for (var i = 0; i < this.sections.length; i++) {
          if (this.displayNoOfQuestions(this.sections[i]) < 1) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },

    openResetQuestionsDialog(sectionName) {
      this.resetSectionSelected = sectionName;
      this.resetQuestionSelectionDialog = true;
    },

    resetQuestionSelection(sectionName) {
      this.$set(this.selectedQuestions, sectionName, []);
      //this.$delete(this.selectedQuestions, sectionName);
      this.resetFolderSelection(sectionName);
      this.resetQuestionSelectionDialog = false;
    },

    resetFolderSelection(sectionName) {
      this.selectedFolder[sectionName] = "";
    },

    durationRules() {
      return [
        (value) => !!value || "Required.",
        (value) => value >= 1 || `Value should be at least 1 minute.`,
        (value) =>
          value <= this.maxTestDuration ||
          `Value should be less than or equal to ${this.maxTestDuration} minutes.`,
      ];
    },

    //also manual resets data that form.reset() cannot
    resetTestForm() {
      this.$refs.testNameForm.reset();
      this.stepper = 1;
      this.$refs.coursesForm.reset();
      this.$refs.markingForm.reset();
      this.$refs.sectionForm.reset();
      this.$refs.timingsForm.reset();
      this.$refs.settingsForm.reset();

      this.selectedCourses = [];
      this.selectedTestGroup = {};
      this.selectedFolder = {};
      this.resetSectionSelected = "";
      this.sections = [];
      this.selectedQuestions = {};
      this.startDate = dayjs().format().substr(0, 10);
      this.startTime = null;
      this.endDate = dayjs().format().substr(0, 10);
      this.endTime = null;
      this.testDuration = 1;
    },

    calcQuestionCount() {
      const questionCountObj = this.questionCountObj;
      var count = 0;

      for (var key of Object.keys(questionCountObj)) {
        count += questionCountObj[key];
      }

      return count;
    },

    deployTest() {
      this.setLoading(true);
      this.error = "";

      const unix_timestamp_id = this.testObj ? this.testObj.id : dayjs().unix();

      var selectedCourses = [...this.selectedCourses];

      this.selectedCourses.forEach((course) => {
        var course_testGroup = `${course}_${this.selectedTestGroup[course]}`;
        selectedCourses.push(course_testGroup);
      });

      const payload = {
        id: `${unix_timestamp_id}`,
        testName: this.testName,
        additionalInstructions: this.additionalInstructions,
        selectedCourses,
        selectedTestGroup: this.selectedTestGroup,
        rewardPoints: parseFloat(this.rewardPoints),
        punishmentPoints: parseFloat(this.punishmentPoints),
        sections: this.sections,
        selectedQuestions: this.selectedQuestions,
        questionCount: this.calcQuestionCount(),
        startDateTime: dayjs(this.startDate + " " + this.startTime).toDate(),
        endDateTime: dayjs(this.endDate + " " + this.endTime).toDate(),
        testDuration: parseInt(this.testDuration),
        submitBeforeTime: this.submitBeforeTime,
        isPremium: this.isPremium,
        isQuiz: this.isQuiz,
        isHighlighted: this.isHighlighted,
        allowSectionSwitching: this.allowSectionSwitching,
        isBilingual: this.isBilingual,
        onlySecondaryLang: this.onlySecondaryLang,
      };

      // submit increases respective counters, update does not. (leaves us to handle increment and decrement)
      const courseTestGroupEqual = isEqual(
        this.oldTestGroups,
        this.selectedTestGroup
      );

      const actionName = this.testObj ? "updateTest" : "submitTest";

      this.$store
        .dispatch(actionName, payload)
        .then(() => {
          if (actionName == "updateTest" && !courseTestGroupEqual) {
            var newTestGroups = this.selectedTestGroup;

            var increment = {
              courses: [],
              testGroups: {},
            };

            var decrement = {
              courses: [],
              testGroups: {},
            };

            //check for changes(except additions) in this.selectedTestGroup
            //as compared to this.oldTestGroups;
            Object.keys(this.oldTestGroups).forEach((course) => {
              //course exists in new
              if (course in newTestGroups) {
                //check if testGroup is not same in old and new : test group changed
                if (this.oldTestGroups[course] != newTestGroups[course]) {
                  //decrement old testGroup for course
                  decrement.testGroups[course] = this.oldTestGroups[course];

                  //increment new testGroup for course
                  increment.testGroups[course] = newTestGroups[course];
                }
              } else {
                //course doesn't exist in new : course deleted

                //decrement course
                decrement.courses.push(course);

                //decrement old testGroup for course
                decrement.testGroups[course] = this.oldTestGroups[course];
              }

              //delete the checked/looped values from newTestGroups (to check for additions)
              delete newTestGroups[course];
            });

            //loop throught the remaining newTestGroups to check for additions
            Object.keys(newTestGroups).forEach((course) => {
              increment.courses.push(course);
              increment.testGroups[course] = newTestGroups[course];
            });

            const incrementPayload = {
              increment,
              decrement,
            };

            //send increment/decrement requests to server
            this.$store
              .dispatch("testUpdateIncrement", incrementPayload)
              .then(() => {
                this.snackbarText = "Test deployed successfully :)";
                this.resetTestForm();
                this.$router.push("/home");
              })
              .catch((error) => {
                console.log(error);
                this.error = error;
                this.snackbarText = "Error, please try again :(";
                this.stepper = 1;
              })
              .finally(() => {
                this.snackbar = true;
                this.setLoading(false);
              });
          } else {
            this.snackbarText = "Test deployed successfully :)";
            this.resetTestForm();
            this.$router.push("/home");
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.snackbarText = "Error, please try again :(";
          this.stepper = 1;
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    fillTestForm() {
      this.testName = this.testObj.testName;
      this.additionalInstructions = this.testObj.additionalInstructions;
      this.selectedCourses = Object.keys(this.testObj.selectedTestGroup);
      this.selectedTestGroup = this.testObj.selectedTestGroup;
      this.rewardPoints = `${this.testObj.rewardPoints}`;
      this.punishmentPoints = `${this.testObj.punishmentPoints}`;
      this.sections = this.testObj.sections;
      this.selectedQuestions = this.testObj.selectedQuestions;

      this.startDate = dayjs(this.testObj.startDateTime.seconds * 1000)
        .format()
        .substr(0, 10);

      this.startTime = dayjs(this.testObj.startDateTime.seconds * 1000).format(
        "HH:mm"
      );

      this.endDate = dayjs(this.testObj.endDateTime.seconds * 1000)
        .format()
        .substr(0, 10);

      this.endTime = dayjs(this.testObj.endDateTime.seconds * 1000).format(
        "HH:mm"
      );

      this.testDuration = `${this.testObj.testDuration}`;
      this.submitBeforeTime = this.testObj.submitBeforeTime;
      this.isPremium = this.testObj.isPremium;
      this.isQuiz = this.testObj.isQuiz;
      this.isHighlighted = this.testObj.isHighlighted;
      this.allowSectionSwitching = this.testObj.allowSectionSwitching;
      this.isBilingual = this.testObj.isBilingual;
      this.onlySecondaryLang = this.testObj.onlySecondaryLang;

      //fill this.selectedFolder
      for (var key of Object.keys(this.selectedQuestions)) {
        if (!(this.selectedQuestions[key] instanceof Array)) {
          // if folder Selected
          this.$set(
            this.selectedFolder,
            key,
            this.selectedQuestions[key].folderName
          );
        }
      }
    },

    fetchFolders() {
      this.setLoading(true);
      //get folder names from server
      this.$store
        .dispatch("getQuestionFolders")
        .then((res) => {
          if (res) {
            this.questionFolders = res.folderNames;
          }
        })
        .catch(() => {
          this.error = "Network error in fetching folders, please try again.";
        })
        .finally(() => {
          this.setLoading(false);
        });
    },

    fetchCourses() {
      //get folder names from server
      this.$store
        .dispatch("getMetaDoc")
        .then((res) => {
          if (res) {
            this.courses = this.coursesArrToObj(res.courses);
          }
        })
        .catch(() => {
          this.error =
            "Network error in fetching courses, please refresh and try again :(";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    coursesArrToObj(coursesArr) {
      var allCoursesObj = {};
      coursesArr.forEach(
        (courseObj) => (allCoursesObj[courseObj.courseName] = courseObj)
      );

      return allCoursesObj;
    },
  },

  mounted() {
    this.setLoading(true);
    this.fetchFolders(); //also gets number of questions in folders
    this.fetchCourses();

    if (this.testObj) {
      //store original selectedTestGroup for comparison on update
      //(storing this way to trigger reactivity - important!!!)
      Object.keys(this.testObj.selectedTestGroup).forEach((course) => {
        this.$set(
          this.oldTestGroups,
          course,
          this.testObj.selectedTestGroup[course]
        );
      });

      this.fillTestForm();
    }
  },
};
</script>

<template>
  <div id="selectQuestionsDialog">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark small v-bind="attrs" v-on="on">
          Select Questions
        </v-btn>
      </template>
      <v-card tile>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title class="text-capitalize"
            >'{{ sectionName }}' Questions
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            <v-icon class="mr-1">mdi-content-save</v-icon>Save
          </v-btn>
        </v-toolbar>
        <QuestionsCard
          :superLoading="superLoading"
          @setSuperLoading="setLoading"
          :enableSelect="true"
          :selectedQuestions="selectedQuestions"
          @questionsSelected="emitSelectedQuestions"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QuestionsCard from "@/components/Home/Manage/QuestionBank/QuestionsCard.vue";

export default {
  name: "SelectQuestionsDialog",
  props: ["superLoading", "sectionName", "selectedQuestions"],
  components: { QuestionsCard },
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    dialog: false,
    error: "",
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
    emitSelectedQuestions(selectedQuestions) {
      const payload = {
        selectedQuestions,
        sectionName: this.sectionName,
      };
      this.$emit("questionsSelected", payload);
    },
  },
  mounted() {},
};
</script>
